<script setup>
import {
  onMounted,
  reactive,
  toRefs,
  defineProps,
  defineComponent,
  computed,
} from "vue";
import axios from "axios";
import TopicsRow from "./TopicsRow.vue";
import Paginate from "vuejs-paginate-next";
import VueScrollTo from "vue-scrollto";

defineComponent({ TopicsRow, Paginate });

const props = defineProps({
    show_heading: {
      type: Boolean,
      required: false,
      default: true,
    },
    show_paginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    per_page: {
      type: Number,
      required: false,
      default: 10,
    },
  }),
  categories_json_path = `/topics/mt_category_list.json?${Date.now()}`,
  topics_list_json_path = `/topics/mt_topics_list.json?${Date.now()}`,
  state = reactive({
    categories: [],
    active_category: "all",
    originList: [],
    topics_list: [],
    currentPage: 1,
    changePage: true,
  }),
  // eslint-disable-next-line no-promise-executor-return
  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  onCategoryClick = async (id) => {
    state.active_category = id;

    state.changePage = false;
    await delay(300);
    state.topics_list =
      state.active_category === "all"
        ? state.originList
        : state.originList.filter((row) => {
            return row.category_id === state.active_category;
          });

    state.changePage = true;
    if (!props.show_paginate) {
      state.topics_list = state.topics_list.slice(0, props.per_page);
    }
  },
  clickCallback = async (pageNum) => {
    state.changePage = false;
    state.currentPage = Number(pageNum);
    await VueScrollTo.scrollTo("#topics_top", 300, { offset: -100 });
    await delay(500);
    state.changePage = true;
  },
  getItems = computed(() => {
    const current = state.currentPage * props.per_page,
      start = current - props.per_page;
    return state.topics_list.slice(start, current);
  }),
  getPageCount = computed(() => {
    return Math.ceil(state.topics_list.length / props.per_page);
  });

onMounted(() => {
  axios.get(categories_json_path).then((res) => {
    state.categories = res.data;
  });

  axios.get(topics_list_json_path).then((res) => {
    state.originList = res.data;
    state.originList.sort((x, y) => {
      const firstDate = new Date(x.date),
        SecondDate = new Date(y.date);
      if (firstDate < SecondDate) return 1;
      if (firstDate > SecondDate) return -1;
      return 0;
    });
  });

  onCategoryClick("all");
});
toRefs(state);
</script>

<template>
  <div class="c-tab">
    <div class="top-news__top">
      <h2 v-if="props.show_heading" class="__heading">
        お知らせ<span>News</span>
      </h2>
      <ul id="topics_top" class="c-news-category">
        <li class="c-news-category__item">
          <button
            class="c-news-category__label"
            :class="{ '-active': state.active_category === 'all' }"
            @click="onCategoryClick('all')"
          >
            全て
          </button>
        </li>
        <li
          v-for="(value, key) in state.categories"
          :key="`category_${key}`"
          class="c-news-category__item"
        >
          <button
            class="c-news-category__label"
            :class="{ '-active': state.active_category === value.id }"
            @click="onCategoryClick(value.id)"
          >
            {{ value.name }}
          </button>
        </li>
      </ul>
    </div>
    <div
      id="tab01"
      class="c-tab__panel"
      role="tabpanel"
      tabindex="0"
      aria-labelledby="tab-1"
    >
      <transition name="fade">
        <ul v-show="state.changePage" class="c-news-list">
          <li
            v-for="(value, key) in getItems"
            :key="`topics_list_${key}`"
            class="c-news-list__item"
          >
            <div v-if="Number(value.nolink) === 1" class="c-news-list__link">
              <TopicsRow :row="value" />
            </div>
            <a
              v-else
              class="c-news-list__link"
              :href="value.permalink"
              :target="Number(value.blank) === 1 ? '_blank' : '_self'"
            >
              <TopicsRow :row="value" />
            </a>
          </li>
        </ul>
      </transition>
      <nav v-if="show_paginate" class="m-pager u-mt-m">
        <paginate
          v-model="state.currentPage"
          :page-count="getPageCount"
          :page-range="5"
          :margin-pages="2"
          :click-handler="clickCallback"
          prev-text=""
          next-text=""
          container-class="m-pager__inner"
          page-class="m-pager__item"
          page-link-class="__elem"
          prev-class="m-pager__item"
          prev-link-class="__elem _prev_"
          next-class="m-pager__item"
          next-link-class="__elem _next_"
          break-view-class="break-view-class"
          break-view-link-class="__elem"
          active-class="_active_"
          disabled-class="_disabled_"
          :hide-prev-next="true"
        />
      </nav>
    </div>
  </div>
  <a href="/topics/index.html" class="e-btn _next_">
    <span class="__elem">もっと見る</span>
  </a>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
