<script setup>
import { defineProps } from "vue";
import dateformat from "dateformat";

const props = defineProps({
    row: {
      type: Object,
      required: true,
      // eslint-disable-next-line no-empty-function
      default: () => {},
    },
  }),
  org_date_format = (target, format) => {
    const target_date = new Date(target);
    return dateformat(target_date, format);
  };
</script>

<template>
  <time
    class="c-news-list__date"
    :datetime="org_date_format(props.row.date, 'yyyy-mm-dd')"
  >
    {{ org_date_format(props.row.date, "yyyy.mm.dd") }}
  </time>
  <div class="c-news-list__category">
    <span class="e-label" :class="`_${props.row.category_id}_`">
      {{ props.row.category_label }}
    </span>
  </div>
  <div class="c-news-list__txt">
    <span class="__elem">
      {{ props.row.title }}
    </span>
  </div>
</template>

<style scoped></style>
